/* @media (max-width: 1023px) {
}
@media (max-width: 767px) {
}
@media (max-width: 480px) {
} */

/* font */
@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
.notosanskr * { 
 font-family: 'Noto Sans KR', sans-serif;
}

/* nomal */
body, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'Noto Sans KR', sans-serif;
}

body * {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

/* library */
.bg-red {
  background-color: red;
}

.bg-gold {
  background-color: gold;
}

.bg-blue {
  background-color: blue;
}

.bg-gray {
  background: #f5f6f9;
}

/* custom */
:root {
  --header-width: 1400px;
  --header-height: 65px;
  --header-mobile-height: 50px;
  --detail-width: 1200px;
  --detail-right-width: 350px;
}

@media (max-width: 1023px) {
    :root {
        --header-height: 60px;
    }
}

:root {

  --page-width: 1200px;
  --page-padding-pc: 0 40px;
  --page-padding-tab: 0 30px;
  --page-padding-mo: 0 20px;
  --border-solid: 1px solid #111;
}

.block  {
  display: block !important;
}

.none {
  display: none !important;
}

.none-pc {
    display: none !important;
}
.none-mo {
    display: block !important;
}

.flex {
  display: flex !important;
}

.inline {
  display: inline !important;
}

.visible {
  visibility: visible !important;
}

.overflow-visible {
  overflow: visible !important;
}

.max-h100vh {
  max-height: 100vh !important;
}

.padding-top-20px {
  padding-top: 20px !important;
}

.underline {
  text-decoration: underline !important;
}

.bold {
    font-weight: bold !important;
}

.br-pc {
    display: block;
}
.br-mo {
    display: none;
}

@media (max-width: 1023px) {
    .br-pc {
        display: none !important;
    }    
    .br-mo {
        display: block !important;
    }
    .none-pc {
        display: block !important;
    }
    .none-mo {
        display: none !important;
    }   
}

/* ========================================================= */

.intro {
  min-height: 100vh;
}

@media (max-width: 1023px) {
  .intro {
    padding: 0 10px;
  }
}


/* ========================================================= */

.form {
  margin: 0 auto;
  margin-top: 30px;
  max-width: 900px;
  width: 100%;
  border: var(--border-solid);
  /* color: #f9e0d0; */
}

.form-header__logo > li {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-header__logo > li > img {
  width: 80px;

}

.form-header:not(:nth-child(1)){
  border-top: var(--border-solid);
}

.form-header > ul {
  display: flex;
  text-align: center;
  align-items: center;
}

.form-header > ul:not(:nth-child(1)) {
  border-top: var(--border-solid);
}

.form-header > ul > li:nth-child(1) {
  flex: 1;
  /* background-color: gold; */
}

.form-header > ul > li:nth-child(2),
.form-header > ul > ul:nth-child(2),
.form-header > ul > div:nth-child(2) {
  width: 70%;
  border-left: var(--border-solid);
}

.form-header > ul > div > ul {
  display: flex;
  align-items: center;
}

.form-header > ul > div > ul:not(:nth-child(1)),
.form-header > ul > div > li:not(:nth-child(1)) {
  border-top: var(--border-solid);
}

.form-header > ul > div > ul > li {
  flex: 1
}
.form-header > ul > div > ul > div {
  width: 50%;
}

.form-header > ul > div > ul > div {
  border-left: var(--border-solid);
}

.form-header > ul > div > ul > div > li:not(:nth-child(1)) {
  border-top: var(--border-solid);
}

@media (max-width: 1023px) {
}

/* ========================================================= */

.form select,
.form input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 16px;
  text-align: center;
}

.form-header__request input {
  width: auto !important;
}

.form-law > li:nth-child(2) > span {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: -1px;
  cursor: pointer;
}

.form-submit {
  /* background-color: rgb(26, 101, 158); */
  background-image: linear-gradient(45deg, #4077de 0%, #012394 100%);
  
}

.form-submit > input {
  padding: 5px 0;
  font-size: 16px;
  font-weight: 600;
  color: #fff !important;
  background-color: transparent;
  cursor: pointer;
}

/* ========================================================= */

.radio-wrap {
  margin: 0 10px;
  display: inline-block;
}

.radio-wrap > input {
  width: auto;
}

/* ========================================================= */

.law__bg {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  height: 100vh;
  background-color: #11111186;
}

.law__content {
  position: absolute;
  padding: 30px 20px;
  left: 50%;
  top: 50%;
  max-width: 600px;
  width: 100%;
  height: 500px;
  transform: translate(-50%, -50%);
  /* max-width: 600px; */
  border-radius: 20px;
  box-shadow: 2px 2px 3px #efefef;
  overflow-y: scroll;
  background-color: #fff;
}

.law__content > :nth-child(1) {
  /* margin-top: 30px; */
  font-size: 25px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

.law__content > :nth-child(2) {
  margin-top: 30px;
  font-size: 15px;
  line-height: 21px;
  font-weight: 300;
  letter-spacing: -0.5px;
  text-align: left;
}

.law__content > img {
  position: absolute;
  width: 20px;
  right: 30px;
  top: 30px;
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 1023px) {
  .law__content {
    max-width: 340px;
  }
}

/* ========================================================= */

.preview {
  margin-left: 10px;
  font-size: 14px;
  cursor: pointer;
}

.preview__content {
  text-align: center !important;
}
/* ========================================================= */
.upload {
  min-height: 100vh;
  margin: 0 20px;
}

.upload__con {
  margin: 0 auto;
  margin-top: 3%;
  padding: 30px;
  max-width: 500px;
  width: 100%;
  min-height: 400px;
  border: 1px solid #6a6a6a;
  box-shadow: 2px 2px 3px #afafaf;
}

.upload__con > div {
  font-size: 25px;
  line-height: 35px;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-align: center;
}

.upload__con > ul {
  margin-top: 60px;

}

.upload__con > ul > li:not(:first-child) {
  margin-top: 60px;
}

.upload__con > ul > li {
  font-size: 16px;
  line-height: 26px;
}

.upload__con > ul > li > span {
  font-size: 18px;
  line-height: 30px;
  font-weight: 600;
}

.upload__con > ul > li:last-child {
  text-align: right;
}

.upload__con > ul > li:last-child > img {
  width: 100px;
}

@media (max-width: 1023px) {
  .upload__con > div {
    font-size: 20px;
    line-height: 30px;
    /* font-weight: 600; */
    /* letter-spacing: -0.5px; */
    /* text-align: center; */
  }
  
  .upload__con > ul {
    margin-top: 30px;
  }
  
  .upload__con > ul > li:not(:first-child) {
    margin-top: 30px;
  }

  .upload__con > ul > li {
    font-size: 14px;
    line-height: 22px;
  }
  
  .upload__con > ul > li > span {
    font-size: 16px;
    line-height: 24px;
    /* font-weight: 600; */
  }

  .upload__con > ul > li:last-child > img {
    width: 60px;
  }
}
/* ========================================================= */

.footer {
  margin-top: 30px;
  padding: 20px 0;

  /* custom */
  background: rgb(162, 162, 162);
  color: #1b1b1b;

}

.footer__con {
  padding: var(--page-padding-pc);
  margin: 0 auto;
  width: 100%;
  max-width: var(--page-width);

  display: flex;
  position: relative;
}

.footer__logo {
  width: 150px;
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: white;

  font-weight: bold;
}

.footer__logo > img {
  width: 50%;
}

.footer__txt01 {
  padding-bottom: 10px;
  font-size: 13px;
  line-height: 24px;
}

.footer__txt02 {
  font-size: 16px;
}

.footer__law-con {
  display: flex;
  position: absolute;
  right: 40px;
  bottom: 0px;
  font-size: 13px;
}

@media (max-width: 1023px) {
  .footer {
      padding-bottom: 50px;
  }
  .footer__con {
      padding: var(--page-padding-tab);
      flex-direction: column;
  }
  .footer__logo {
      margin-bottom: 10px;
      justify-content: left;
      font-size: 24px;
  }
  .footer__txt01 {
      font-size: 12px;
      line-height: 20px;
  }
  
  .footer__txt02 {
      font-size: 14px;
  }    
  .footer__law-con {
      bottom: 20px;
  }
}
@media (max-width: 767px) {
  .footer__con {
      padding: var(--page-padding-mo);
  }
}
@media (max-width: 480px) {
}


/* ========================================================= */

.license__con {
    padding: var(--page-padding-pc);
    margin: 0 auto;
    width: 100%;
    max-width: var(--page-width);
  } 
  
  .license__grid-con { 
    margin: 100px 0;
    display: flex;
    flex-wrap: wrap;
  }
  
  .license__grid-item {
    margin: 30px 10px 30px 10px;
    width: calc( (100% - 2 * 20px) / 2 );
    display: flex;
    flex-direction: column;
    /* cursor: pointer; */
    position: relative;
  }
  
  .license__source-name {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -1px;
    color: #428ef9;
    cursor: pointer;
  }
  
  .license__source-url {
    letter-spacing: -1px;
    opacity: 0.7;
    font-weight: 400;
    color: darkblue;
    text-decoration: underline;
  }
  
  .license__source-type {
    letter-spacing: -1px;
    opacity: 0.7;
  }
  
  
  .license__source-writer {
    font-weight: 500;
    letter-spacing: -1px;
    line-height: 22px;
  }
  
  .license__txt-item {
    padding: 30px 0;
    border-top: 1px solid #afafaf;
  }
  
  .license__txt-title {
    font-size: 25px;
    font-weight: 600;
    text-align: center;
  }
  
  .license__txt-sub {
    margin-top: 30px;
    font-size: 14px;
    font-weight: 300;
    color: #222222;
  }
  
  
  @media (max-width: 1023px) {
    .license__con {
      padding: var(--page-padding-tab);
    } 
  
    .license__txt-sub {
      font-size: 18px;
    }
  }
  
  @media (max-width: 767px) {
    .license__con {
      padding: var(--page-padding-mo);
    }
  
    .license__grid-item {
      margin: 15px 0px 15px 0px;
      margin-bottom: 15px;
      width: calc( (100% - 2 * 10px) / 2 );
    }
  
    .license__grid-item:nth-child(2n-1) {
      margin-right: 20px;
    }
  
    .license__source-name {
      font-size: 18px;
    }
  
    
    .license__source-type {
      font-size: 15px;
    }
  
    .license__source-writer {
      font-size: 15px;
      font-weight: 400;
    }
  
   
  }
  @media (max-width: 480px) {
  }
  
  